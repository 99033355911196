// CSS
require('../themes/material_pro/assets/plugins/bootstrap/css/bootstrap.min.css');
require('../themes/material_pro/assets/plugins/chartist-js/dist/chartist.min.css');
require('../themes/material_pro/assets/plugins/chartist-js/dist/chartist-init.css');
require('../themes/material_pro/assets/plugins/chartist-plugin-tooltip-master/dist/chartist-plugin-tooltip.css');
require('../themes/material_pro/assets/plugins/c3-master/c3.min.css');
require('../themes/material_pro/assets/plugins/select2/dist/css/select2.min.css');
require("../themes/material_pro/assets/plugins/dropify/dist/css/dropify.css");
require('../themes/material_pro/assets/plugins/bootstrap-select/bootstrap-select.min.css');
require('../css/daterangepicker/daterangepicker.css');
require('../css/datepicker/bootstrap-datepicker.min.css');
require('../themes/material_pro/assets/plugins/timepicker/bootstrap-timepicker.min.css');

// LIGHT
//require('../themes/material_pro/material/css/style.css');
//require('../themes/material_pro/material/css/colors/default.css');
// DARK
//require('../themes/material_pro/dark/css/style.css');
//require('../themes/material_pro/dark/css/colors/green-dark.css');

// SASS
require('../themes/material_pro/material/scss/style.scss');
require('../themes/material_pro/material/scss/colors/default-aware.scss');

// JS
require('../themes/material_pro/assets/plugins/bootstrap/js/bootstrap.min.js');
require('../themes/material_pro/dark/js/jquery.slimscroll.js');
require('../themes/material_pro/dark/js/waves.js');
require('../themes/material_pro/dark/js/sidebarmenu.js');
require('../themes/material_pro/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js');
require('../themes/material_pro/assets/plugins/sparkline/jquery.sparkline.min.js');
require('../themes/material_pro/assets/plugins/select2/dist/js/select2.full.js');
require('../themes/material_pro/dark/js/custom.js');
require('../themes/material_pro/assets/plugins/datatables/jquery.dataTables.min.js');
require('../themes/material_pro/assets/plugins/dropify/dist/js/dropify.min.js');
require('../themes/material_pro/assets/plugins/bootstrap-select/bootstrap-select.min.js');
require('../themes/material_pro/assets/plugins/daterangepicker/daterangepicker.js');
require('../themes/material_pro/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js');
require('../themes/material_pro/assets/plugins/timepicker/bootstrap-timepicker.min.js');


// IMPORTS (require chart js doesn't work)
import Chart from 'chart.js';

// Modules (yarn installed, to override outdated inside theme)
//-- Gridstack
//require('gridstack');
//require('../themes/material_pro/assets/plugins/gridstack/gridstack.css');
//require('../themes/material_pro/assets/plugins/gridstack/gridstack-extra.css');
//require('gridstack/dist/gridstack.jQueryUI.js');